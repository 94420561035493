import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { useRef } from "react";
import logo from "../../../assets/Home/LeafCheck/logo.png";
import first from "../../../assets/Home/LeafCheck/1.mov";
import second from "../../../assets/Home/LeafCheck/2.mov";
import third from "../../../assets/Home/LeafCheck/3.mov";

const LeafCheck = () => {
  const [t, i18n] = useTranslation(); //translation hook
  const swiperRef = useRef(null);

//   useEffect(() => {
//     if (swiperRef.current) {
//       swiperRef.current.update(); // Re-initialize Swiper after layout changes
//     }
//   }, [i18n.language]); 

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {t("Leaf-Level Precision Enables ")}
        <span>{t("Fast")}</span>
        <span>{t(", ")}</span>
        <span>{t("Decisive Action")}</span>
        <span>.</span>
      </h2>
      <div className={styles.card}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <div className={styles.cardWrapper}>
          {/* <Swiper
            modules={[Autoplay]}
            autoplay={{ delay: 6000, disableOnInteraction: false }} // Autoplay settings
            loop={true} // Enable infinite looping
            slidesPerView={1} // Show one slide at a time
            className={styles.videoSlider}
          > */}
          {/* <Swiper
            className={styles.swiper}
            modules={[Autoplay]}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            loop={true}
            slidesPerView={1}
            onSlideChange={() => {
              // Pause all videos when the slide changes
              const videos = document.querySelectorAll("video");
              videos.forEach((video) => video.pause());
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onTransitionEnd={() => {
              // Play the active video
              const activeSlide =
                swiperRef.current.slides[swiperRef.current.activeIndex];
              const video = activeSlide.querySelector("video");
              if (video) video.play();
            }}
          >
            <SwiperSlide>
              <video
                muted
                autoPlay
                loop
                // onEnded={() => {
                //   // Move to the next slide when the video ends
                //   swiperRef.current?.slideNext();
                // }}
              >
                <source src={first} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </SwiperSlide>
            <SwiperSlide>
              <video
                muted
                autoPlay
                loop
                // onEnded={() => {
                //   // Move to the next slide when the video ends
                //   swiperRef.current?.slideNext();
                // }}
              >
                <source src={second} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </SwiperSlide>
            <SwiperSlide>
              <video
                muted
                autoPlay
                loop
                // onEnded={() => {
                //   // Move to the next slide when the video ends
                //   swiperRef.current?.slideNext();
                // }}
              >
                <source src={third} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </SwiperSlide>

             <SwiperSlide>
              <img src={first} alt="Slide 1" className={styles.gif} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={second} alt="Slide 2" className={styles.gif} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={third} alt="Slide 3" className={styles.gif} />
            </SwiperSlide> 
          </Swiper> */}
          <Swiper
            className={styles.swiper}
            modules={[]}
            loop={true}
            slidesPerView={1}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={() => {
              if (!swiperRef.current) return;

              // Pause all videos
              const videos = document.querySelectorAll("video");
              videos.forEach((video) => video.pause());

              // Play the active slide's video after a short delay
              setTimeout(() => {
                const activeSlide =
                  swiperRef.current.slides?.[swiperRef.current.activeIndex];
                if (activeSlide) {
                  const video = activeSlide.querySelector("video");
                  if (video) video.play().catch(() => {}); // Catch any errors silently
                }
              }, 100); // Short delay to prevent overlap
            }}
          >
            <SwiperSlide>
              <video
                muted
                autoPlay
                loop={false}
                onEnded={() => {
                  if (swiperRef.current) swiperRef.current.slideNext();
                }}
              >
                <source src={first} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </SwiperSlide>
            <SwiperSlide>
              <video
                muted
                autoPlay
                loop={false}
                onEnded={() => {
                  if (swiperRef.current) swiperRef.current.slideNext();
                }}
              >
                <source src={second} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </SwiperSlide>
            <SwiperSlide>
              <video
                muted
                autoPlay
                loop={false}
                onEnded={() => {
                  if (swiperRef.current) swiperRef.current.slideNext();
                }}
              >
                <source src={third} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default LeafCheck;
