import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import egroscout from "../../../assets/Home/Packages/egroscout.png";
import egrocare from "../../../assets/Home/Packages/egrocare.png";
import leftLogo from "../../../assets/Home/Packages/left-logo.png";
import rightLogo from "../../../assets/Home/Packages/right-logo.png";
import check from "../../../assets/Home/Packages/check.png";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

const Packages = () => {
  const [t, i18n] = useTranslation(); //translation hook

  return (
    <div className={styles.packagesSection}>
      <h2 className={styles.heading} dir={i18n.dir()}>
        {t("Flexible Plans ")}
        <span>{t("To Meet Retailer")}</span>
        <span>{t(" & ")}</span>
        <span>{t("Grower Needs")}</span>
        <span>{t(".")}</span>
      </h2>

      <div className={styles.pricingContainer}>
        {/* Main Pack */}
        <div className={styles.pricingCard}>
          <div>
            <img
              src={egrocare}
              alt="Egrocare icon"
              style={{ width: "81px", height: "20px" }}
            />
            <h3 className={styles.cardTitle}>{t("Main Pack.")}</h3>
          </div>
          <ul className={styles.featuresList}>
            <li>
              <img src={check} alt="Check icon" />
              {t("Farms & Fields management (1 farm, 5 fields).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Tasks management (Max 50 tasks).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Inventory & Invoices (Max 50 invoices).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Agricultural engineer support (Max 3 consultations).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Employees & Assets (5 employees, 2 assets).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Premium options: Add-ons for extended use and features.")}
            </li>
          </ul>

          <div className={styles.pricing}>
            <div className={styles.firstCard}>
              <p>
                {t("Annually")}
                <span className={styles.amount}>
                  <span>4000</span>
                  <span className={styles.currency}>{t("LE")}</span>
                </span>
              </p>
            </div>
            <div className={styles.secondCard}>
              <p>
                {t("Monthly")}
                <span className={styles.amount}>
                  <span>333</span>
                  <span className={styles.currency}>{t("LE")}</span>
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* Mid Pack */}
        <div className={styles.pricingCard}>
          <div>
            <img
              src={egrocare}
              alt="Egrocare icon"
              style={{ width: "81px", height: "20px" }}
            />

            <h3 className={styles.cardTitle}>{t("Mid Pack.")}</h3>
          </div>
          <ul className={styles.featuresList}>
            <li>
              <img src={check} alt="Check icon" />
              {t("Farms & Fields management (3 farms, 15 fields).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Tasks management (Max 150 tasks).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Inventory & Invoices (Max 100 invoices).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Financial management (Full unit).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Agricultural engineer support (Max 5 consultations).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Employees & Assets (20 employees, 10 assets).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t(
                "Premium options: Advanced reports, Additional consultations."
              )}
            </li>
          </ul>
          <div className={styles.pricing}>
            <div className={styles.firstCard}>
              <p>
                {t("Annually")}
                <span className={styles.amount}>
                  <span>8000</span>
                  <span className={styles.currency}>{t("LE")}</span>
                </span>
              </p>
            </div>
            <div className={styles.secondCard}>
              <p>
                {t("Monthly")}
                <span className={styles.amount}>
                  <span>667</span>
                  <span className={styles.currency}>{t("LE")}</span>
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* Advanced Pack */}
        <div className={styles.pricingCard}>
          <div>
            <img
              src={egrocare}
              alt="Egrocare icon"
              style={{ width: "81px", height: "20px" }}
            />
            <h3 className={styles.cardTitle}>{t("Advanced Pack.")}</h3>
          </div>
          <ul className={styles.featuresList}>
            <li>
              <img src={check} alt="Check icon" />
              {t("Farms & Fields management (Unlimited).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Tasks management (Unlimited).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Inventory & Invoices (Unlimited).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Financial management (Advanced).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Agricultural engineer support (Unlimited).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Employees & Assets (Unlimited).")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t(
                "Premium options: Customized support, Internet of things integration, API."
              )}
            </li>
          </ul>

          <div className={styles.pricing}>
            <div className={styles.firstCard}>
              <p>
                {t("Annually")}
                <span className={styles.amount}>
                  <span>20,000</span>
                  <span className={styles.currency}>{t("LE")}</span>
                </span>
              </p>
            </div>
            <div className={styles.secondCard}>
              <p>
                {t("Monthly")}
                <span className={styles.amount}>
                  <span> 1,667</span>
                  <span className={styles.currency}>{t("LE")}</span>
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* Egro Scout */}
        <div className={styles.pricingCard}>
          <div>
            <img
              src={egroscout}
              alt="Egroscout icon"
              style={{ width: "81px", height: "20px" }}
            />

            <h3 className={styles.cardTitle}>{t("Egro Scout.")}</h3>
          </div>
          <ul className={styles.featuresList}>
            <li>
              <img src={check} alt="Check icon" />
              {t("Self-Scanning robot.")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("90% accuracy in detecting diseases.")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Fruit accurate counting.")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Detailed health reports.")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Improve resource utilization.")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Excellent technical support.")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("4 visits recommended annually.")}
            </li>
            <li>
              <img src={check} alt="Check icon" />
              {t("Discounts for large spaces.")}
            </li>
          </ul>
          <div className={styles.pricing}>
            <div className={styles.egroscoutFirstCard}>
              <p>{t("The Price Varies According To The Farm Area:")}</p>
              <p>{t("1-10 Acres 1000 EGP/Acre")}</p>
              <p>{t("11-50 Acres 500 EGP/Acre")}</p>
              <p>{t("+50 Acres 300 EGP/Acre")}</p>
            </div>
            <div className={styles.secondCard}>
              <p>
                {t("Acre/Visit")}
                <span className={styles.amount}>
                  <span>300-1000</span>
                  <span className={styles.currency}>{t("LE")}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.ctaSection}>
        <img src={leftLogo} alt="Left Logo" className={styles.leftLogo} />
        <div>
          <p>{t("Pick A Plan At Affordable Per Acre Pricing.")}</p>
          <Link to="/contact">
            <button className={styles.ctaButton}>
              {t("Let's Get Started")}
            </button>
          </Link>
        </div>
        <img src={rightLogo} alt="Right Logo" className={styles.rightLogo} />
      </div>
    </div>
  );
};

export default Packages;
