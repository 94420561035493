import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const KnowMore = () => {
  const [t, i18n] = useTranslation(); //translation hook

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t("Do You Want To Know More?")}</h2>
      <Link to="/contact">
        <button className={styles.button}>{t("Contact Us Now")}</button>
      </Link>
    </div>
  );
};
export default KnowMore;
