import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import styles from "./styles.module.css";
import logo from "../../assets/Footer/logo.png";
import facebook from "../../assets/Footer/facebook.png";
import linkedin from "../../assets/Footer/linkedin.png";
import tiktok from "../../assets/Footer/tiktok.png";
import { useTranslation, Trans } from "react-i18next";

const Footer = () => {
  const [t, i18n] = useTranslation(); //translation hook

  return (
    <>
      <div className={styles.footerContainer}>
        <div className={styles.footerContent}>
          <div className={styles.iconsRow}>
            <img src={logo} alt="Logo" className={styles.logo} />
            <div className={styles.socialIcons}>
              <a
                href="https://www.facebook.com/egrobots"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook}
                  alt="Facebook icon"
                  className={styles.icons}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/egrobots/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={linkedin}
                  alt="LinkedIn icon"
                  className={styles.icons}
                />
              </a>
              <a
                href="https://www.tiktok.com/@egrobots?_t=8iHjSrdTO10&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={tiktok} alt="Tiktok icon" className={styles.icons} />
              </a>
            </div>
          </div>
          {/* 
          <div className={styles.footerLinks}>
            <a href="#egro-scout">Egro Scout</a>
            <a href="#egro-care-pro">Egro Care Pro</a>
            <a href="#egro-scan">Egro Scan</a>
            <a href="#about-egrobots">About Egrobots</a>
            <a href="#how-it-works">How It Works?</a>
          </div> */}

          <p className={styles.copyright}>
           {t("Copyright © 2010-2024 Egrobots Company. All Rights Reserved")}
          </p>
        </div>
      </div>
    </>
  );
};
export default Footer;
