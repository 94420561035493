import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n";
import i18n from "i18next";
import axios from "axios";

i18n.on("languageChanged", (lng) => {
  document.documentElement.lang = lng; // Update the lang attribute
  document.documentElement.dir = lng === "ar" ? "rtl" : "ltr"; // Update the dir attribute
});

axios.defaults.baseURL = "https://egrobots.com/egrocare-api/egrocare/";
// axios.defaults.baseURL = "http://34.226.212.12/egrocare-api/egrocare/";
// axios.defaults.baseURL = "http://localhost:8080/";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
