import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import logo from "../../assets/Header/logo.png";
import arFlag from "../../assets/Header/ar.png";
import enFlag from "../../assets/Header/en.png";
import products from "../../assets/Header/products.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [t, i18n] = useTranslation(); //translation hook

  return (
    <header className={styles.menu}>
      <div className={styles.logoContainer}>
        <img loading="lazy" src={logo} alt="logo" className={styles.logo} />
        <span className={styles.logoText}>EGROBOTS</span>
      </div>
      <nav className={styles.navItems}>
        {/* <a href="#" className={styles.navLink}>
          {t("About Egrobots")}
        </a> */}
        {/* <a href="#" className={styles.navLink}>
          {t("How it Works?")}
        </a> */}
        <Link to="/contact">
          <button className={styles.contactButton}>{t("Contact Us")}</button>
        </Link>
        {i18n.language === "en" && (
          <img
            loading="lazy"
            src={arFlag}
            alt="Arabic"
            className={styles.icon}
            onClick={() => i18n.changeLanguage("ar")}
          />
        )}
        {i18n.language === "ar" && (
          <img
            loading="lazy"
            src={enFlag}
            alt="English"
            className={styles.icon}
            onClick={() => i18n.changeLanguage("en")}
          />
        )}
        {/* <img
          loading="lazy"
          src={products}
          alt=""
          className={styles.productsIcon}
        /> */}
      </nav>
    </header>
  );
};

export default Header;